import React, { createContext, useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
  CurrentItemType,
  FormStatusType,
  FormStepProviderType,
  SetStepProps,
  Steps,
  StepValue,
} from '@src/providers/ordering/types';

const FormStatus = createContext(null as unknown as FormStepProviderType);

export const useFormStatusState = () => {
  const context = useContext(FormStatus);
  if (!context) {
    throw new Error(
      'useFormStatusState should be used within FormStatusProvider!',
    );
  }

  const {
    completionIndex,
    currentItem,
    activeItem,
    activeStep,
    prevStep,
    nextStep,
  } = context;

  return {
    completionIndex,
    currentItem,
    activeItem,
    activeStep,
    prevStep,
    nextStep,
  };
};

export const useFormStatusHandlers = () => {
  const context = useContext(FormStatus);
  if (!context) {
    throw new Error(
      'useFormStatusHandlers should be used within FormStatusProvider!',
    );
  }

  const {
    setSteps,
    setActiveItem,
    setCurrentItem,
    updateCurrentItem,
    updateCompletionIndex,
    goNext,
    goBack,
    resetSteps,
  } = context;

  return {
    setSteps,
    setActiveItem,
    setCurrentItem,
    updateCurrentItem,
    updateCompletionIndex,
    goNext,
    goBack,
    resetSteps,
  };
};

const FormStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentItem, setCurrentItem] = useState<CurrentItemType>(() => {
    if (typeof window !== 'undefined') {
      const item = localStorage.getItem('current-item');
      if (item) {
        return JSON.parse(item);
      }

      return null as unknown as CurrentItemType;
    }

    return null as unknown as CurrentItemType;
  });
  const [formStatus, setFormStatus] = useState<FormStatusType>(() => {
    if (typeof window !== 'undefined') {
      const activeStep =
        (localStorage?.getItem('activeStep') as StepValue) ?? Steps.CATEGORY;
      const nextStep =
        (localStorage?.getItem('nextStep') as StepValue) ?? Steps.DETAILS;
      const prevStep = (localStorage?.getItem('prevStep') as StepValue) ?? '';
      const activeItem = localStorage?.getItem('activeItem') ?? '';

      return {
        completionIndex: 1,
        activeStep,
        activeItem,
        prevStep,
        nextStep,
      };
    }

    return {
      completionIndex: 1,
      activeStep: Steps.CATEGORY,
      activeItem: '',
      prevStep: '',
      nextStep: Steps.DETAILS,
    };
  });

  const updateCompletionIndex = (index: 1 | 2 | 3) => {
    setFormStatus((prevState) => ({
      ...prevState,
      completionIndex: index,
    }));
  };

  const setSteps = ({ activeStep, nextStep, prevStep }: SetStepProps) => {
    setFormStatus((prevState) => ({
      ...prevState,
      activeStep,
      nextStep,
      prevStep,
    }));
  };

  const resetSteps = () => {
    setSteps({
      activeStep: Steps.CATEGORY,
      nextStep: Steps.DETAILS,
      prevStep: '',
    });

    if (window) {
      localStorage?.setItem('activeStep', Steps.CATEGORY);
      localStorage?.setItem('nextStep', Steps.DETAILS);
      localStorage?.setItem('prevStep', '');
    }
  };

  const goNext = () => {
    const index = Object.values(Steps).findIndex(
      (item) => item === formStatus.nextStep,
    );

    if (formStatus.activeStep === Steps.DETAILS) updateCompletionIndex(1);
    if (formStatus.activeStep === Steps.OVERVIEW) updateCompletionIndex(2);
    if (formStatus.activeStep === Steps.DELIVERY) updateCompletionIndex(3);

    const activeStep =
      formStatus.completionIndex === 3 &&
      formStatus.activeStep === Steps.DETAILS
        ? Steps.SUMMARY
        : formStatus.nextStep;

    setFormStatus((prev) => ({
      ...prev,
      activeStep: formStatus.nextStep,
      prevStep: formStatus.activeStep,
      nextStep: Object.values(Steps)[index + 1] ?? '',
    }));
  };

  const goBack = () => {
    if (formStatus.prevStep === 'cart') {
      navigate('/cart');
      return;
    }

    if (formStatus.prevStep === Steps.OVERVIEW) updateCompletionIndex(1);
    if (formStatus.prevStep === Steps.DELIVERY) updateCompletionIndex(2);
    if (formStatus.prevStep === Steps.SUMMARY) updateCompletionIndex(3);

    const index = Object.values(Steps).findIndex(
      (item) => item === formStatus.prevStep,
    );

    setFormStatus((prev) => ({
      ...prev,
      activeStep: formStatus.prevStep,
      nextStep: formStatus.activeStep,
      prevStep: Object.values(Steps)[index - 1] ?? '',
    }));
  };

  const setActiveItem = (id: string) => {
    setFormStatus((prev) => ({
      ...prev,
      activeItem: id,
    }));

    if (typeof window !== 'undefined') {
      localStorage?.setItem('activeItem', id);
    }
  };

  const updateCurrentItem = (
    key: keyof CurrentItemType,
    value: CurrentItemType[keyof CurrentItemType],
  ) => {
    setCurrentItem((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const keys = Object.keys(formStatus) as (keyof FormStatusType)[];
      keys.forEach((key) => {
        localStorage?.setItem(key, formStatus[key].toString());
      });
    }
  }, [formStatus]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('current-item', JSON.stringify(currentItem));
    }
  }, [currentItem]);

  return (
    <FormStatus.Provider
      value={{
        currentItem,
        updateCurrentItem,
        updateCompletionIndex,
        setCurrentItem,
        ...formStatus,
        setSteps,
        setActiveItem,
        goBack,
        goNext,
        resetSteps,
      }}
    >
      {children}
    </FormStatus.Provider>
  );
};

export default FormStatusProvider;
