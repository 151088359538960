exports.components = {
  "component---src-pages-403-index-tsx": () => import("./../../../src/pages/403/index.tsx" /* webpackChunkName: "component---src-pages-403-index-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-500-index-tsx": () => import("./../../../src/pages/500/index.tsx" /* webpackChunkName: "component---src-pages-500-index-tsx" */),
  "component---src-pages-agb-index-tsx": () => import("./../../../src/pages/agb/index.tsx" /* webpackChunkName: "component---src-pages-agb-index-tsx" */),
  "component---src-pages-anfahrt-kontakt-index-tsx": () => import("./../../../src/pages/anfahrt-kontakt/index.tsx" /* webpackChunkName: "component---src-pages-anfahrt-kontakt-index-tsx" */),
  "component---src-pages-annahmestellen-index-tsx": () => import("./../../../src/pages/annahmestellen/index.tsx" /* webpackChunkName: "component---src-pages-annahmestellen-index-tsx" */),
  "component---src-pages-app-pakete-index-tsx": () => import("./../../../src/pages/app-pakete/index.tsx" /* webpackChunkName: "component---src-pages-app-pakete-index-tsx" */),
  "component---src-pages-auftragsabwicklung-index-tsx": () => import("./../../../src/pages/auftragsabwicklung/index.tsx" /* webpackChunkName: "component---src-pages-auftragsabwicklung-index-tsx" */),
  "component---src-pages-bewertungen-index-tsx": () => import("./../../../src/pages/bewertungen/index.tsx" /* webpackChunkName: "component---src-pages-bewertungen-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-diascanner-mieten-index-tsx": () => import("./../../../src/pages/diascanner-mieten/index.tsx" /* webpackChunkName: "component---src-pages-diascanner-mieten-index-tsx" */),
  "component---src-pages-facebook-index-tsx": () => import("./../../../src/pages/facebook/index.tsx" /* webpackChunkName: "component---src-pages-facebook-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-geschaeftskunden-index-tsx": () => import("./../../../src/pages/geschaeftskunden/index.tsx" /* webpackChunkName: "component---src-pages-geschaeftskunden-index-tsx" */),
  "component---src-pages-gutscheine-index-tsx": () => import("./../../../src/pages/gutscheine/index.tsx" /* webpackChunkName: "component---src-pages-gutscheine-index-tsx" */),
  "component---src-pages-impressum-index-tsx": () => import("./../../../src/pages/impressum/index.tsx" /* webpackChunkName: "component---src-pages-impressum-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-index-tsx": () => import("./../../../src/pages/instagram/index.tsx" /* webpackChunkName: "component---src-pages-instagram-index-tsx" */),
  "component---src-pages-jetzt-bewerten-bewertungsabgabe-index-tsx": () => import("./../../../src/pages/jetzt-bewerten/bewertungsabgabe/index.tsx" /* webpackChunkName: "component---src-pages-jetzt-bewerten-bewertungsabgabe-index-tsx" */),
  "component---src-pages-jetzt-bewerten-gmail-index-tsx": () => import("./../../../src/pages/jetzt-bewerten/gmail/index.tsx" /* webpackChunkName: "component---src-pages-jetzt-bewerten-gmail-index-tsx" */),
  "component---src-pages-jetzt-bewerten-index-tsx": () => import("./../../../src/pages/jetzt-bewerten/index.tsx" /* webpackChunkName: "component---src-pages-jetzt-bewerten-index-tsx" */),
  "component---src-pages-kodachrome-index-tsx": () => import("./../../../src/pages/kodachrome/index.tsx" /* webpackChunkName: "component---src-pages-kodachrome-index-tsx" */),
  "component---src-pages-leistungen-aps-filme-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/aps-filme-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-aps-filme-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-betamax-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/betamax-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-betamax-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-dias-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/dias-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-dias-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-dokumente-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/dokumente-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-dokumente-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-fotoalben-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/fotoalben-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-fotoalben-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-fotos-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/fotos-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-fotos-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-grossformat-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/großformat-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-grossformat-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-hi-8-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/hi8-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-hi-8-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-kassetten-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/kassetten-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-kassetten-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-minidv-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/minidv-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-minidv-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-mittelformat-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/mittelformat-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-mittelformat-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-negative-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/negative-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-negative-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-schallplatten-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/schallplatten-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-schallplatten-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-sonderformate-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/sonderformate-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-sonderformate-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-super-8-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/super-8-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-super-8-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-tonbaender-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/tonbaender-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-tonbaender-digitalisieren-index-tsx" */),
  "component---src-pages-leistungen-vhs-digitalisieren-index-tsx": () => import("./../../../src/pages/leistungen/vhs-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-leistungen-vhs-digitalisieren-index-tsx" */),
  "component---src-pages-lieferzeit-weihnachten-index-tsx": () => import("./../../../src/pages/lieferzeit-weihnachten/index.tsx" /* webpackChunkName: "component---src-pages-lieferzeit-weihnachten-index-tsx" */),
  "component---src-pages-maintenance-index-tsx": () => import("./../../../src/pages/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-maintenance-index-tsx" */),
  "component---src-pages-ordering-index-tsx": () => import("./../../../src/pages/ordering/index.tsx" /* webpackChunkName: "component---src-pages-ordering-index-tsx" */),
  "component---src-pages-preise-index-tsx": () => import("./../../../src/pages/preise/index.tsx" /* webpackChunkName: "component---src-pages-preise-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-probescan-index-tsx": () => import("./../../../src/pages/probescan/index.tsx" /* webpackChunkName: "component---src-pages-probescan-index-tsx" */),
  "component---src-pages-reklamation-index-tsx": () => import("./../../../src/pages/reklamation/index.tsx" /* webpackChunkName: "component---src-pages-reklamation-index-tsx" */),
  "component---src-pages-speichermedium-index-tsx": () => import("./../../../src/pages/speichermedium/index.tsx" /* webpackChunkName: "component---src-pages-speichermedium-index-tsx" */),
  "component---src-pages-thank-you-index-tsx": () => import("./../../../src/pages/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-thank-you-index-tsx" */),
  "component---src-pages-video-grabber-vhs-selbst-digitalisieren-index-tsx": () => import("./../../../src/pages/video-grabber-vhs-selbst-digitalisieren/index.tsx" /* webpackChunkName: "component---src-pages-video-grabber-vhs-selbst-digitalisieren-index-tsx" */),
  "component---src-pages-videokassetten-entsorgen-index-tsx": () => import("./../../../src/pages/videokassetten-entsorgen/index.tsx" /* webpackChunkName: "component---src-pages-videokassetten-entsorgen-index-tsx" */),
  "component---src-pages-widerrufsbelehrung-index-tsx": () => import("./../../../src/pages/widerrufsbelehrung/index.tsx" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-index-tsx" */),
  "component---src-pages-zahlung-und-versand-index-tsx": () => import("./../../../src/pages/zahlung-und-versand/index.tsx" /* webpackChunkName: "component---src-pages-zahlung-und-versand-index-tsx" */)
}

