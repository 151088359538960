import React from 'react';
import { Picture, SuperEight, Video, Document } from '@src/domain/types';

export const Steps = {
  CATEGORY: 'select_category',
  DETAILS: 'service_details',
  OVERVIEW: 'overview',
  DELIVERY: 'delivery_info',
  SUMMARY: 'summary',
} as const;

export type StepValue = (typeof Steps)[keyof typeof Steps];
export type StepType = StepValue | 'cart' | '';

export type SetStepProps = {
  activeStep: StepType;
  nextStep: StepType;
  prevStep: StepType;
};

export type FormStatusType = {
  completionIndex: 1 | 2 | 3;
  activeStep: StepValue | 'cart' | '';
  prevStep: StepValue | 'cart' | '';
  nextStep: StepValue | 'cart' | '';
  activeItem: string;
};

export type CurrentItemType = Picture | Video | SuperEight | Document;

type CurrentItemKey = keyof CurrentItemType;
type CurrentItemValue = CurrentItemType[CurrentItemKey];

export type FormStepProviderType = FormStatusType & {
  currentItem: CurrentItemType;
  updateCurrentItem(key: CurrentItemKey, value: CurrentItemValue): void;
  setCurrentItem: React.Dispatch<React.SetStateAction<CurrentItemType>>;
  setSteps({ activeStep, nextStep, prevStep }: SetStepProps): void;
  setActiveItem(id: string): void;
  resetSteps(): void;
  updateCompletionIndex(index: 1 | 2 | 3): void;
  goNext: () => void;
  goBack: () => void;
};
