import React, { createContext, useContext, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { FormType } from '@src/components/pages/ordering/types';

interface CartCounterContext {
  counter: number;

  resetCounter(): void;

  increaseCounter(): void;

  decreaseCounter(): void;
}

const CartCounterContext = createContext(null as unknown as CartCounterContext);

export const useCartCounter = () => {
  const context = useContext(CartCounterContext);
  if (!context) {
    throw new Error(
      'useCartCounter should be used within CartCounterProvider.',
    );
  }

  return context;
};

const CartCounterProvider = ({ children }: { children: React.ReactNode }) => {
  const [cart] = useLocalStorage<FormType | null>('cart', null);
  const [counter, setCounter] = useState(() => {
    if (!cart || (cart && cart.ids.length === 0)) {
      return 0;
    }

    return cart.ids.length;
  });

  const resetCounter = () => setCounter(0);
  const increaseCounter = () => setCounter((prev) => prev + 1);
  const decreaseCounter = () => setCounter((prev) => prev - 1);

  return (
    <CartCounterContext.Provider
      value={{
        counter,
        resetCounter,
        increaseCounter,
        decreaseCounter,
      }}
    >
      {children}
    </CartCounterContext.Provider>
  );
};

export default CartCounterProvider;
